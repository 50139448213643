const TOAST_TIME = 10000;

export const useToastsStore = defineStore('toasts', () => {
  const toasts = ref([]);

  function setSuccessToast(toast) {
    setToast({
      type: 'success',
      icon: 'check',
      ...toast,
    });
  }

  function setFailedToast(toast) {
    setToast({
      type: 'failed',
      icon: 'times',
      ...toast,
    });
  }

  function setToast(toast) {
    const id = (toasts.value[toasts.value.length]?.id ?? 0) + 1;
    toasts.value.push({ id, ...toast });

    setTimeout(() => {
      const index = toasts.value.findIndex((toast) => toast.id === id);
      if (index > -1) {
        toasts.value.splice(index, 1);
      }
    }, TOAST_TIME);
  }

  function removeToast(id) {
    const index = toasts.value.findIndex((toast) => toast.id === id);
    if (index > -1) {
      toasts.value.splice(index, 1);
    }
  }

  return {
    setToast,
    setSuccessToast,
    setFailedToast,
    removeToast,
    toasts,
  };
});
